import React, { FunctionComponent } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
// import { useCMS, useForm, usePlugin } from "tinacms";
import { Opportunity } from "./Opportunity";

export const EventsOpportunities: FunctionComponent = () => {
  //   const cms = useCMS();
  const eventsCollection = useFirestore()
    .collection("opportunities")
    .orderBy("timestamp", "desc");
  //   .get();
  const data = useFirestoreCollectionData<any>(eventsCollection);

  return (
    <div className="px-4 md:px-8 py-8 bg-green-100">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="pl-4 md:pl-0 text-3xl sm:text-5xl font-extrabold">
          Events <span style={{ color: "#ed8433" }}>and</span> Opportunities
        </h3>
        <div className="flex flex-wrap md:grid md:grid-cols-3">
          {data.map((x) => {
            return (
              <Opportunity
                category={"INNOVATION"}
                title={x.title}
                date={x.date}
                description={x.description}
                imagePath={x.imageUrl}
                url={x.externalLink}
              />
            );
          })}
        </div>
        {/* <div className="flex">
          <div className="flex-1"></div>
          <div className="hidden lg:block flex-1 pl-8"></div>
        </div> */}
      </div>
    </div>
  );
};
