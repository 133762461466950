import React, { FunctionComponent } from "react";
import saislogo from "../../assets/images/sais-logo-2.jpg";
// import idinlogo from "../../assets/images/idin-logo-1.png";
import undplogo from "../../assets/images/undp.png";
import { useCMS, useForm, usePlugin } from "tinacms";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const Programmes: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("our-programmes");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "7.ntbc-our-programmes",
    label: "4. Our Programmes Section",
    fields: [
      {
        name: "firstLogo",
        label: "First Programme Logo",
        component: "image",
      },
      {
        name: "firstProgrammeBody",
        label: "First Programme Body",
        component: "markdown",
      },
      {
        name: "secondLogo",
        label: "Second Programme Logo",
        component: "image",
      },
      {
        name: "secondProgrammeBody",
        label: "Second Programme Body",
        component: "markdown",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="px-8 py-8">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="text-3xl sm:text-5xl font-extrabold">
          Strategic <span className="text-green-600">Partners</span>
        </h3>
        <div className="flex">
          <div className="flex-1">
            <img src={saislogo} alt="SAIS logo" className="h-16 lg:h-20 my-4" />
            <p className="text-gray-900 text-xl font-semibold mb-2">
              Southern African Innovation Collective
            </p>
            <p>
              The Southern Africa Innovation Collective (SAIC) is a unique,
              cohesive body of innovation and entrepreneurship ecosystem
              supporters that work together to have a lasting positive impact on
              innovators and entrepreneurs across Southern Africa. Originally
              founded as the Connected Hubs initiative by the Southern Africa
              Innovation Support Programme in 2017, we serve as a community of
              learning and an innovation and entrepreneurship support network
              currently spanning five SADC countries namely; Zambia, Botswana,
              South Africa, Namibia and Tanzania
            </p>
            <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
              <li className="py-1">
                Referrals to non-funding and funding Instruments.
              </li>
              <li className="py-1">
                Access to unique training ,mentorship and capacity building
                workshops
              </li>
              <li className="py-1">
                Linkages to local ,regional and global, networks and markets,
                access to unique diverse business development services
              </li>
              <li className="py-1">
                Access to expert advisory services about Innovation and
                entrepreneurship in Southern Africa.
              </li>
            </ul>
          </div>
          <div className="hidden lg:block flex-1 pl-8">
            <img src={undplogo} alt="IDIN logo" className="h-16 lg:h-20 my-4" />
            <p className="text-gray-900 text-xl font-semibold mb-2">
              National Innovation Initiative
            </p>
            <p>
              The National Technology Business Centre (NTBC) and the United
              Nations Development Programme (UNDP) Accelerator Lab have been
              implementing the National Innovation Initiative (NII) with support
              from collaborating partners since 2020. NII is aimed at mapping
              out the existing innovations across Zambian communities with a
              view of supporting the most promising initiatives with the highest
              potential impact. The programme focus on scaling up existing
              innovations in communities across the Country that have potential
              to significantly impact the development of these societies and
              accelerate the achievement of the Sustainable Development Goals
              (SDGs)
            </p>
            <p className="mt-4 text-gray-900">The NII objectives are: </p>
            <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
              <li className="py-1">
                Recognise and Support promising local home-grown solutions that
                exist within communities
              </li>
              <li className="py-1">
                Provide a platform for innovations to be showcased for funding
                opportunities and partnerships with potential off takers
              </li>
              <li className="py-1">
                Promote experimentation in the development process of solutions
              </li>
              <li className="py-1">
                Building innovators and start-up's ability to apply learnt
                skills in attracting support and turning their innovations into
                businesses.{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
