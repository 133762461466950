import React, { Fragment, FunctionComponent } from "react";
import { NTBCHero } from "./sections/hero";
import { AboutContent } from "./sections/about-us";
import { ServicesSummary } from "./sections/our-services";
import { Programmes } from "./sections/our-programmes";
// import { OtherProgrammes } from "./sections/other-programmes/OtherProgrammes";
import { Library } from "./sections/resource-center";
// import { CommercializationCTA } from "./sections/call-to-actions";
import { Partnerships } from "./sections/our-partners";
import { ContactUs } from "./sections/contact-us";
import { Footer } from "./sections/footer";
import { useFirestore, useFirestoreDocData } from "reactfire";

import { EventsOpportunities } from "./sections/events-opportunities/EventsOpportunities";
import { NewsSection } from "./sections/news/NewsSection";

const OurProgrammes: FunctionComponent = () => {
  const promoRef = useFirestore()
    .collection("website-images")
    .doc("x5ibpNeFSmeum5Geibg7");
  const promotionImage = useFirestoreDocData<any>(promoRef);

  const commRef = useFirestore()
    .collection("website-images")
    .doc("LuQZukfrdMlLENBMhGxx");
  const comImage = useFirestoreDocData<any>(commRef);

  const businessRef = useFirestore()
    .collection("website-images")
    .doc("we1WWKZemztFBOixsNhk");
  const businessImage = useFirestoreDocData<any>(businessRef);

  const techTransferRef = useFirestore()
    .collection("website-images")
    .doc("wPW04oUdOlnFvTO6oUeK");
  const techTransferImage = useFirestoreDocData<any>(techTransferRef);

  return (
    <div>
      <div className="px-8 py-8">
        <div className="max-w-screen-xl mx-auto">
          <h3 className="text-3xl sm:text-5xl font-extrabold mb-3">
            Our <span className="text-green-600">Programmes</span>
          </h3>
          <div className="flex">
            <div className="flex-1">
              <p className="text-2xl font-semibold mb-2">
                Innovation Promotion
              </p>
              <p>
                This program includes activities relating to but not limited to
                Innovation/technology scouting, conducted through participation
                at profiled shows and fairs (Trade Fair, Agriculture show,
                Science week, etc.), innovation challenges, and general calls
                for submission of innovations/technologies. The program also
                assists innovators to brand and launch new products
              </p>
              <img
                src={promotionImage.path}
                alt="ntbc"
                className="h-80 w-full object-cover rounded mt-4 shadow-lg"
              />
            </div>
            <div className="hidden lg:block flex-1 pl-8">
              <p className="text-2xl font-semibold mb-2">Commercialization</p>
              <p>
                Involves activities by which products /processes/ innovations
                are nurtured and brought out to the market for utilisation.
                Typically, several steps sum up the commercialization cycle,
                i.e., technical assessments, market assessments, and business
                development services. The outputs of commercialisation may
                include:
              </p>
              <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
                <li className="py-1">Start up or spin off company formation</li>
                <li className="py-1">
                  Technology Transfer agreements (mainly in form of licensing)
                </li>
                <li className="py-1">Research and Development Agreements </li>
              </ul>
              <img
                src={comImage.path}
                alt="ntbc"
                className="h-72 w-full object-cover rounded mt-4 shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 py-8">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex">
            <div className="flex-1">
              <p className="text-2xl font-semibold mb-2">
                Business Development
              </p>
              <p>
                Focuses on developing a business out of an innovation for
                commercial gain. It comprises several tasks and processes
                generally aimed at developing and implementing growth
                opportunities. Besides assisting innovators/start-ups with
                business plan evaluation and development, financial linkages,
                financial management skills, business modelling, and access to
                business networks, the program also provides:
              </p>
              <ul className="text-gray-900 list-disc pl-4 lg:pl-16 pt-2">
                <li className="py-1">
                  Technology Business Incubation: Aimed at providing operating
                  premises and other business development support services to
                  start-ups, innovators and micro, small and medium enterprises
                  (MSMEs).
                </li>
                <li className="py-1">
                  Technology Business Development Fund (TBDF): Provides funding
                  to technology-based innovations to develop sustainable and
                  eco-inclusive enterprises.
                </li>
              </ul>
              <img
                src={businessImage.path}
                alt="ntbc"
                className="h-72 w-full object-cover rounded mt-4 shadow-lg"
              />
            </div>
            <div className="hidden lg:block flex-1 pl-8">
              <p className="text-2xl font-semibold mb-2">Technology Transfer</p>
              <p>
                Involves activities by which technology is obtained, moved, or
                assigned to or from an individual, institution/corporate entity,
                or country. Technology transfer includes a mixture of four
                forms, i.e., capital goods, skilled labour, information and
                organisational methods and procedures.
              </p>
              <img
                src={techTransferImage.path}
                alt="ntbc"
                className="h-96 w-full object-cover rounded mt-4 shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LandingPage: FunctionComponent = () => {
  return (
    <Fragment>
      <NTBCHero />
      <AboutContent />
      <ServicesSummary />
      <OurProgrammes />
      <EventsOpportunities />
      <Programmes />
      <NewsSection />
      {/* <OtherProgrammes /> */}
      <Library />
      {/* <NewsSection /> */}
      <Partnerships />
      <ContactUs />
      <Footer />
    </Fragment>
  );
};
