import React, { Fragment, FunctionComponent, useState } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useCMS, useForm, usePlugin } from "tinacms";

export const NTBCPopup: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("site-notifications");

  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "3.ntbc-popup",
    label: "8. Site Notifications",
    fields: [
      {
        name: "title",
        label: "Notification Title",
        component: "text",
      },
      {
        name: "content",
        label: "Notification Content",
        component: "markdown",
      },
      {
        name: "link",
        label: "Notification Link",
        component: "text",
      },
      {
        name: "published",
        component: "toggle",
        label: "Show Notification",
        description: "Check to mark this to show the site notification.",
        toggleLabels: {
          true: "On",
          false: "Off",
        },
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  const [visible, setVisible] = useState<boolean>(editableData.published);
  usePlugin(form);
  return (
    <Fragment>
      {visible && (
        <div className="fixed z-50 right-0 text-white bottom-8 mx-auto w-full sm:w-1/3">
          <div className="bg-green-600 px-8 py-6 mx-2 rounded">
            <h2 className="text-xl">{editableData.title}</h2>
            <p className="text-green-100 py-2">{editableData.content}</p>
            <a
              href={editableData.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click <span>HERE</span> to learn more.
            </a>
          </div>
          <div
            className="absolute right-4 top-2 z-20 cursor-pointer"
            onClick={() => {
              setVisible(false);
            }}
          >
            <p className="text-sm">[X]</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};
