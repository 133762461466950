import React, { FunctionComponent } from "react";
import { NTBCNavigation } from "../../sections/hero";
import Lottie from "lottie-react";

import gears2 from "../../assets/lottie/19748-gear-animation.json";

export const NTBCServices: FunctionComponent = () => {
    return (
        <div>
            <div className="relative bg-green-50 overflow-hidden">
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8 z-10">
                    <NTBCNavigation />
                </div>
                <main className="text-center mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-10">
                    <div className="sm:text-center lg:text-left">
                        <p className="pb-6 text-gray-500">
                            National Technology and Business Centre
                        </p>
                        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl ">
                            NTBC &nbsp;
                            <span className="text-green-600">Services</span>
                        </h2>
                        <p className="mt-3 text-gray-800 sm:mt-5  sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0 mb-4">
                            Our services are inclined to two core functions
                            i.e., commercialization of innovative products and
                            transfer of technologies through the support pillars
                            outlined below.
                        </p>
                    </div>
                </main>
                <div className="absolute top-0 inset-x-0 sm:left-8 sm:w-1/2 opacity-25">
                    <Lottie animationData={gears2} />
                </div>
            </div>
            <article className="prose mx-auto max-w-screen-xl px-8 lg:px-32 mt-8 mb-8">
                <h3>Technology Aquisition and Transfer</h3>
                <p>
                    In facilitating the transfer of suitable and appropriate
                    technologies to the Zambian Industry, NTBC is adding value
                    to the manufacturing sector, MSMEs, Innovators and
                    Researchers through productivity and quality improvements of
                    products and services. NTBC realises its mandate of
                    Technology Transfer (also called Transfer of Technology and
                    Technology Commercialisation) through transferring skills,
                    knowledge, technologies, methods of manufacturing, samples
                    of manufacturing and facilities among Government, Academia
                    and Industry
                </p>
                <p>The Technology Transfer service entails:</p>
                <ul>
                    <li>
                        Facilitating acquisition, adoption, development,
                        transfer and deployment of Technology to Entrepreneurs
                        (from local and international R&D institutions and
                        innovators)
                    </li>
                    <li>
                        Drafting of technology licenses and acquirement of
                        Technology Transfer agreements
                    </li>
                    <li>
                        Developing more efficient processes for the
                        identification and selection of Technology for adoption
                        and utilization in Zambia
                    </li>
                    <li>Conducting Technology Needs Assessments</li>
                    <li>
                        Technology brokering/ Technology valorisation – linking
                        technology seekers or users (industry) to technology
                        suppliers in order to improve production of goods and
                        services.
                    </li>
                    <li>
                        Providing technical advice to the commercialization
                        processes of Research, Development and Innovation (RDI)
                        products.
                    </li>
                    <li>
                        Facilitating Intellectual Property Rights protection of
                        Innovations/inventions and advice on Patent prosecution
                        and utilisation
                    </li>
                    <li>
                        Generating Technology landscape, technology forecasting/
                        and fore-sighting
                    </li>
                    <li>Providing engineering advisory service</li>
                </ul>
                <h3>Provision of Technology Information</h3>
                <p>
                    The Centre provides Technology Information through its
                    Resource Centre. Through this medium, NTBC provides
                    technology information to leverage formation of businesses
                    that involve Innovative and efcient products and services.
                    Its overall goal is to provide entrepreneurs and innovators
                    with technological information in order to facilitate
                    Commercialisation and Technology Transfer. Established in
                    December 2009, it represents numerous opportunities for
                    business development and improvement of various production
                    processes.
                </p>
                <p>This service entails providing information on:</p>
                <ul>
                    <li>
                        New technologies for improved production of goods and
                        services
                    </li>
                    <li>Intellectual Property</li>
                    <li>Investment opportunities</li>
                    <li>Technology suppliers and seekers</li>
                    <li>Technology landscapes</li>
                </ul>
                <h3>Technology Marketing and Innovation Promotion</h3>
                <p>
                    This service aims to transform new ideas, innovations and
                    technologies into products developed and commercialised by:
                </p>
                <ul>
                    <li>Aiding Idea conceptualisation</li>
                    <li>Facilitating product development and improvement</li>
                    <li>Conducting market research</li>
                    <li>Developing market strategies</li>
                    <li>Launching new products and rebranding</li>
                    <li>Developing platforms for innovation promotion</li>
                </ul>
                <h3>Business Development Support Services</h3>
                <p>
                    NTBC undertakes the assessment of commercial value of
                    technologies and research outputs to create sustainable and
                    eco-inclusive businesses. This is delivered through:
                </p>
                <ul>
                    <li>Business plan evaluation and development</li>
                    <li>Financial linkages</li>
                    <li>Financial management skills</li>
                    <li>Business modelling</li>
                    <li>Operating space</li>
                    <li>Access to business networks</li>
                    <li>Coaching & Mentorship</li>
                </ul>
                <h4> Technology Business Incubation Programme (TBIP)</h4>
                <p>
                    The TBIP is aimed at providing Business Development Support
                    to viable impact driven start-ups to increase their
                    competitiveness in the market. The enterprises graduate from
                    the programme within a dened period.
                </p>
                <h4>Technology Business Development Fund (TBDF)</h4>
                <p>
                    The Technology Business Development Fund (TBDF) provides
                    funding to technology based innovations to develop
                    sustainable and eco-inclusive enterprises. The targeted
                    beneciaries include; innovators, entrepreneurs, Small and
                    Medium Enterprises (SMEs), Research Development
                    institutions.
                </p>
                <p>Some of the beneficiaries include:</p>
                <ul>
                    <li>
                        Hot rolled Organic Oats – Kasisi Agricultural Training
                        Centre
                    </li>
                    <li>Baobab Fruit juice – Mbwama enterprises</li>
                    <li>
                        Mobility Aids – Appropriate Paper Technologies (APTERS)
                        Zambia
                    </li>
                    <li>Moringa Instant Porridge (Flour) – Mondie Moringa</li>
                </ul>
                <h3>Technology Audit and Validation</h3>
                <p>
                    In line with ensuring the quality production of goods and
                    services by industry for increased competitiveness, the
                    Centre has a responsibility to monitor the use and uptake of
                    technology by industry. NTBC takes stock of technologies in
                    use by industry and validates their use against existing
                    standards drawn by various regulatory bodies to achieve full
                    plant optimisation and cleaner production.
                </p>
                <p>This service involves:</p>
                <ul>
                    <li>Assessing production processes and systems</li>
                    <li>Skills and knowledge exchange</li>
                    <li>
                        Developing standard operation and maintenance procedures
                    </li>
                    <li>Developing a database of technologies in use</li>
                    <li>Providing intellectual property valuation</li>
                </ul>
            </article>
        </div>
    );
};
