import React, { FunctionComponent } from "react";
import { NTBCNavigation } from "../../sections/hero";
import Lottie from "lottie-react";

import gears2 from "../../assets/lottie/19748-gear-animation.json";

export const PrivacyPolicy: FunctionComponent = () => {
    return (
        <div>
            <div className="relative bg-green-50 overflow-hidden">
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8 z-10">
                    <NTBCNavigation />
                </div>
                <main className="text-center mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-10">
                    <div className="sm:text-center lg:text-left">
                        <p className="pb-6 text-gray-500">
                            National Technology and Business Centre
                        </p>
                        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl ">
                            Privacy &nbsp;
                            <span className="text-green-600">Policy</span>
                        </h2>
                        <p className="mt-3 text-gray-500 sm:mt-5  sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0 mb-4">
                            This Privacy Policy describes how NTBC collects,
                            uses and discloses information, and what choices you
                            have with respect to the information.
                        </p>
                    </div>
                </main>
                <div className="absolute top-0 inset-x-0 sm:left-8 sm:w-1/2 opacity-25">
                    <Lottie animationData={gears2} />
                </div>
            </div>
            <article className="prose mx-auto max-w-screen-xl px-8 lg:px-32 mt-8 mb-8">
                <h5>Effective: August 11, 2020</h5>
                <h3>Changes to this policy</h3>
                <p>
                    We may update this Policy from time to time. If we do, we’ll
                    let you know about any material changes, either by notifying
                    you on the Site or by sending you an email. New versions of
                    this Policy will never apply retroactively—we’ll tell you
                    the exact date they go into effect. If you keep using the
                    NTBC online platforms after a change, that means you accept
                    this Policy.
                </p>
                <h3>Who we are</h3>
                <p>
                    The National Technology Business Centre (NTBC) is a
                    statutory body under the Ministry of Education. IT was
                    established by Statutory Instrument number 136 of 1999
                    pursuant to Science and Technology Act number 26 of 1997.
                    The Centre has been in operation since 1st April, 2002. Our
                    key role is to assist in the transformation of new ideas,
                    innovation and technologies into products and new businesses
                    that are financially viable. Through our range of services,
                    investment and innovation resources, we aim to graduate
                    products for creation of wealth and employment. We also aim
                    to link development and proven technologies from various
                    local and international sources with the local technology
                    seekers i.e., business community and entreprenuers.
                </p>
                <h3>How This Policy Applies</h3>
                <p>
                    This Privacy Policy applies to NTBC’s platform, including
                    the associated mobile application and web portal
                    (collectively, the “Services”), ntbc.co.zm and other NTBC
                    websites (collectively, the “Websites”) and other
                    interactions (e.g., customer service inquiries, user
                    conferences, etc.) you may have with NTBC. If you do not
                    agree with the terms, do not access or use the Services,
                    Websites or any other online aspect of NTBC’s business.
                </p>
                <h3>Information We Collect And Receive</h3>
                <p>
                    In order for you to create an account with NTBC and use our
                    Services, we need to collect and process certain
                    information. Depending on your use of the Services, that may
                    include:
                </p>
                <ul>
                    <li>
                        Information you provide by completing forms on NTBC
                        Online Platforms — your name, email and postal
                        addresses, telephone number, country of residence,
                        login, and password details. We may ask for this
                        information if you register as a user of the Services,
                        subscribe to our newsletters, upload or submit content
                        through the Online Platforms, or if you contact us;
                    </li>
                    <li>
                        The email address associated with your Google account,
                        if you choose to sign up using your Google credentials.
                        NTBC will also request permission to access your name,
                        and profile picture (these permissions are governed by
                        Google’s privacy policies and can be managed through
                        your Google privacy settings). We never post anything to
                        third-party accounts.
                    </li>
                </ul>
                <p>
                    You may decline to provide us with your information.
                    However, this will limit your ability to register for an
                    account or use our Services.
                </p>
                <h3>How We Use This Information</h3>
                <p>
                    We use the information we collect for the following
                    purposes:
                </p>
                <ul>
                    <li>
                        To keep your account secure and protect our Services
                        (including to verify the identities of creators and
                        prevent fraud and abuse);
                    </li>
                    <li>
                        To enable us to provide you with our Services, and to
                        improve and promote our Services;
                    </li>
                    <li>
                        To create and administer your account, contact you, and
                        customize your experience with the NTBC online platforms
                        (for example, to show you projects that we think may
                        interest you based on your previous use)
                    </li>
                </ul>
            </article>
        </div>
    );
};
