import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-new.png";

export const NTBCNavigation: FunctionComponent = () => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
      <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/" aria-label="Home">
            <img className="h-12 w-auto sm:h-16" src={logo} alt="NTBC Logo" />
          </Link>
        </div>
      </div>
      <div className="hidden md:block md:ml-10 md:pr-4">
        <Link
          to="/services"
          className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >
          Services
        </Link>

        <Link
          to={{ pathname: "https://community.ntbc.co.zm/" }}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >
          Community
        </Link>
        <Link
          to={{ pathname: "https://blog.ntbc.co.zm" }}
          rel="noopener noreferrer"
          target="_blank"
          className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >
          Blog
        </Link>
        <Link
          to={{ pathname: "https://nii.ntbc.co.zm" }}
          rel="noopener noreferrer"
          target="_blank"
          className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
        >
          Apply for NII
        </Link>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex items-center  text-green-800 hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      {isExpanded && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="rounded-lg bg-white shadow-xs overflow-hidden"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => toggleExpansion(!isExpanded)}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    aria-label="Close menu"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                <Link
                  to="/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Home
                </Link>
                <Link
                  to="/services"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Services
                </Link>
                <Link
                  to={{ pathname: "https://community.ntbc.co.zm" }}
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Community
                </Link>
                <Link
                  to={{ pathname: "https://blog.ntbc.co.zm" }}
                  target="_black"
                  className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Blog
                </Link>
                <Link
                  to={{ pathname: "https://nii.ntbc.co.zm" }}
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Apply for NII
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};
