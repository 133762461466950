import React from "react";
import Lottie from "lottie-react";

import loading from "./assets/lottie/28734-multiple-circle-loading-animation.json";

export const NTBCLoadingScreen: React.FC = () => {
  return (
    <div>
      <main className="text-center mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-10">
        <div className="sm:text-center">
          <p className="pb-6 text-gray-500">
            National Technology and Business Centre
          </p>
          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl ">
            Turning &nbsp;
            <span className="text-green-600">ideas</span>
            {" and "}
            <br className="xl:hidden" />
            <span style={{ color: "#ed8433" }}>innovations</span> into wealth.
          </h2>
        </div>
      </main>
      <div className="text-center w-40 mt-6 mx-auto">
        <Lottie animationData={loading} />
      </div>
      <div className="text-center mx-auto">Loading...</div>
    </div>
  );
};
