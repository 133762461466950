import React, { FunctionComponent, Fragment } from "react";
import Slider from "react-slick";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const Partnerships: FunctionComponent = () => {
  const partnersRef = useFirestore().collection("partners");
  const partners = useFirestoreCollectionData<any>(partnersRef);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    centerMode: true,
    initialSlide: 7,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="px-8 py-8">
        <div className="max-w-screen-xl mx-auto">
          <h3 className="text-3xl sm:text-5xl font-extrabold">
            Our <span className="text-green-600">Partners</span>
          </h3>
          <p className="">
            In order to meet its objectives and to deliver on its mandate, NTBC
            works with the following strategic partners among many others.
          </p>
        </div>
      </div>
      <div className="mb-8 overflow-x-hidden">
        <Slider {...settings} lazyLoad={"progressive"}>
          {partners?.map((p) => (
            <div
              className="flex items-center mb-8 h-16 flex-col justify-center"
              key={p.name}
            >
              <img src={p.logo} alt="partner_logo" className="h-full mx-auto" />
              <div className="text-xs text-center w-32 mx-auto pt-1 text-gray-600">
                {p.name}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Fragment>
  );
};
