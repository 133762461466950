import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";

export const ProjectCategory: FunctionComponent = () => {
  const history = useHistory();

  const [category, setCategory] = useLocalStorage(
    "category",
    "Information Technology"
  );

  const toProjectDescription = () => {
    if (category) {
      history.push("/apply/project-description");
    }
  };

  return (
    <div>
      <div className="">
        <h2 className="text-2xl">Let's get started</h2>
        <p className="text-sm text-gray-600 lg:w-1/2">
          Pick a project category. You can update these details later from the
          NTBC mobile application.
        </p>
      </div>
      <div className="">
        <div className="relative mt-4 lg:w-1/2">
          <div className="text-gray-500 pb-2 text-sm">Project Category</div>
          <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="category"
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Information Technology</option>
            <option>Agriculture</option>
            <option>Manufacturing</option>
            <option>Health</option>
            <option>Construction</option>
            <option>Other</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={() => toProjectDescription()}
          className="bg-green-600 hover:bg-green-700 text-green-50 py-2 px-4 rounded inline-flex items-center"
        >
          Next: Project Idea
        </button>
      </div>
      <div className="text-xs text-gray-500 mt-8 lg:w-1/2">
        To submit an innovation, you're required to provide your location, age,
        National ID, email, and mailing address.
      </div>
    </div>
  );
};
