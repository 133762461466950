export const firebaseConfig = {
  apiKey: "AIzaSyCJo_yMI6uO7LeLax7vaffJyKgTUSkrh9U",
  authDomain: "ntbc-conduit.firebaseapp.com",
  databaseURL: "https://ntbc-conduit.firebaseio.com",
  projectId: "ntbc-conduit",
  storageBucket: "ntbc-conduit.appspot.com",
  messagingSenderId: "443703892848",
  appId: "1:443703892848:web:c3819249f8bc40343c4db5",
  measurementId: "G-0DVCVQGB3N",
};

// import React, { useContext, useEffect, useState } from "react";
// import firebase, { initializeApp } from "firebase/app";

// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/storage";

// export const firebaseConfig = {
//   apiKey: "AIzaSyCJo_yMI6uO7LeLax7vaffJyKgTUSkrh9U",
//   authDomain: "ntbc-conduit.firebaseapp.com",
//   databaseURL: "https://ntbc-conduit.firebaseio.com",
//   projectId: "ntbc-conduit",
//   storageBucket: "ntbc-conduit.appspot.com",
//   messagingSenderId: "443703892848",
//   appId: "1:443703892848:web:9b7432e9a714e5783c4db5",
//   measurementId: "G-S98MDL2SX5",
// };

// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// export const db = firebase.firestore;

// const FirebaseContext = React.createContext<firebase.app.App | null>(null);

// export const FirebaseProvider: React.FC = ({ children }) => {
//   const [client] = useState(() => {
//     return initializeApp(firebaseConfig);
//   });

//   console.log(client);

//   return (
//     <FirebaseContext.Provider value={client}>
//       {children}
//     </FirebaseContext.Provider>
//   );
// };

// export const useFirebase = () => {
//   return useContext(FirebaseContext);
// };

// export const useFirestore = () => {
//   const firebase = useFirebase();
//   if (firebase) {
//     return firebase.firestore();
//   }
// };

// export const useStorage = () => {
//   const firebase = useFirebase();
//   if (firebase) {
//     return firebase.storage();
//   }
// };

// type Page = any;

// export const usePageCollection = () => {
//   const firestore = useFirestore();
//   const [pages, setPages] = useState<Page[]>([]);

//   useEffect(() => {
//     if (!firestore) return;

//     firestore
//       .collection("pages")
//       .get()
//       .then((pages) => {
//         setPages(pages.docs.map((doc) => ({ ...doc.data, id: doc.id })));
//       });
//   }, [firestore]);

//   return pages;
// };
