import React, { FunctionComponent } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useCMS, useForm, usePlugin } from "tinacms";
import mobileApp from "../../assets/images/mobile-app.png";

interface IResourceProps {
  svgIconPath: string;
  name: string;
  description: string;
}

const Resource: FunctionComponent<IResourceProps> = (props) => {
  return (
    <div className="pt-4 lg:w-64 lg:mr-4">
      <div className="border border-gray-300 p-4 rounded-lg bg-white shadow-lg">
        <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-600 mb-2">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
          >
            <path d={props.svgIconPath}></path>
          </svg>
        </div>
        <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
          {props.name}
        </h2>
        <p className="leading-relaxed text-sm">{props.description}</p>
      </div>
    </div>
  );
};

export const Library: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("resource-center");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "5.ntbc-resource-center",
    label: "6. Resource Center Section",
    fields: [
      {
        name: "body",
        label: "Body",
        component: "markdown",
      },
      {
        name: "mobileApp",
        label: "Mobile App Summary",
        component: "markdown",
      },
      {
        name: "community",
        label: "Community Summary",
        component: "markdown",
      },
      {
        name: "image",
        label: "Image",
        component: "image",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="px-4 sm:px-8 py-8 bg-green-100">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="px-4 sm:px-0 text-3xl sm:text-5xl font-extrabold">
          Resource <span className="text-green-600">Centre</span>
        </h3>
        <div className="flex">
          <div className="flex-1">
            <p className="text-gray-800 px-4 sm:px-0">{editableData.body}</p>
            <div className="flex flex-wrap">
              <Resource
                svgIconPath="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                name="NTBC Mobile App"
                description={editableData.mobileApp}
              />
              <Resource
                svgIconPath="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0"
                name="NTBC Community"
                description={editableData.community}
              />
              <Resource
                svgIconPath="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                name="NTBC Blog"
                description={editableData.blog}
              />
            </div>
          </div>
          <div className="hidden lg:block flex-1 pl-8">
            <img
              src={mobileApp}
              alt="ntbc-mobile-app"
              className="w-3/4 h-auto mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
