import React, { FunctionComponent } from "react";
import { NTBCNavigation } from "../../sections/hero";
import gears2 from "../../assets/lottie/19748-gear-animation.json";

import Lottie from "lottie-react";

export const NTBCProposals: FunctionComponent = () => {
  return (
    <div>
      <div className="relative bg-green-50 overflow-hidden">
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8 z-10">
          <NTBCNavigation />
        </div>
        <main className="text-center mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-10">
          <div className="sm:text-center lg:text-left">
            <p className="pb-6 text-gray-500">
              National Technology and Business Centre
            </p>
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl ">
              Call &nbsp;
              <span className="text-green-600">For Proposals</span>
            </h2>
            <p className="mt-3 text-gray-800 sm:mt-5  sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0 mb-4">
              The purpose of this call for proposals is to engage a consultant
              who will develop the Business Development Fund framework,
              operational guidelines, staffing and system requirements at NTBC.
            </p>
          </div>
        </main>
        <div className="absolute top-0 inset-x-0 sm:left-8 sm:w-1/2 opacity-25">
          <Lottie animationData={gears2} />
        </div>
      </div>
      <article className="prose mx-auto max-w-screen-xl px-8 lg:px-32 mt-8 mb-8">
        <p>
          The National Technology Business Centre (NTBC) with support from the
          Government of Finland through the Accelerated Growth for Small and
          Medium Enterprise (AGS) Programme in Zambia under the Ministry of
          Commerce Trade and Industry (MCTI) hereby requests the services of a
          Consultant to undertake the development of the framework and
          operational guidelines of the Business Development Fund.
        </p>
        <p>
          The National Technology Business Centre is mandated to establish and
          maintain a Business Development Fund for entrepreneurs in need of
          financial support related to the promotion, commercialization and
          transfer of new technologies/ innovations.
        </p>
        <p>
          The purpose of this call for proposals is to engage a consultant who
          will develop the Business Development Fund framework, operational
          guidelines, staffing and system requirements at NTBC
        </p>
        <p>
          The Terms of Reference are downloadable on{" "}
          <a
            href="https://bit.ly/32zSFy8"
            target="_blank"
            className="text-green-600"
            rel="noopener noreferrer"
          >
            <span className="text-green-600">here</span>
          </a>
          . Submit your bid on bdf@ntbc.co.zm by 23:59 hours on 28nd November
          2020 or The Procurement Officer National Technology Business Centre
          8th Floor New Government Complex PO Box 31510 RW Lusaka
        </p>
        <div className="text-center">
          <button
            className="text-white bg-green-600 border-0 py-2 px-6 focus:outline-none hover:bg-green-800 rounded text-lg"
            onClick={() => window.open("https://bit.ly/32zSFy8", "_blank")}
          >
            Download Terms of Reference
          </button>
        </div>
      </article>
    </div>
  );
};
