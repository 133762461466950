import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LandingPage } from "./NTBCLandingPage";
import { PrivacyPolicy, NTBCServices } from "./pages";
import { NTBCProposals } from "./pages/proposals/Proposals";
import { TinaProvider, TinaCMS } from "tinacms";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./services/firebase";
import { NTBCPopup } from "./NTBCPopup";
import { NTBCLoadingScreen } from "./NTBCLoadingScreen";

function App() {
  const cms = new TinaCMS({
    sidebar: true,
    enabled: false,
    toolbar: true,
  });

  React.useEffect(() => {
    import("react-tinacms-editor").then(
      ({ HtmlFieldPlugin, MarkdownFieldPlugin }) => {
        cms.plugins.add(HtmlFieldPlugin);
        cms.plugins.add(MarkdownFieldPlugin);
      }
    );
  }, [cms.plugins]);

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<NTBCLoadingScreen />}>
        <TinaProvider cms={cms}>
          <Fragment>
            <Switch>
              <Route path="/" exact>
                <LandingPage />
              </Route>
              <Route path="/services" component={NTBCServices} />
              <Route
                path="/blog"
                component={() => {
                  window.location.href = "https://blog.ntbc.co.zm";
                  return null;
                }}
              />
              <Route path="/proposals" component={NTBCProposals} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
            </Switch>
            <NTBCPopup />
          </Fragment>
        </TinaProvider>
      </Suspense>
    </FirebaseAppProvider>
  );
}

export default App;
