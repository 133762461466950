import React, { FunctionComponent } from "react";

export const ContactUs: FunctionComponent = () => {
  return (
    <div className="py-8 relative">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="text-3xl sm:text-5xl font-extrabold">Contact Us</h3>
        <p className="">
          For all your ideas or projects that need commercializing
        </p>
      </div>
      <div className="absolute inset-0 bg-gray-300">
        <iframe
          width="100%"
          height="100%"
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
          title="map"
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6468.489228964962!2d28.29252155449669!3d-15.420471075218401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f3504a27237d%3A0x2b7948a2aea18f9c!2sNew%20Government%20Complex!5e0!3m2!1sen!2szm!4v1597061268844!5m2!1sen!2szm"
          style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
        ></iframe>
      </div>
      <div className="container px-5 py-24 mx-auto flex">
        <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Contact Us
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            For all your ideas or projects that need commercializing
          </p>
          <input
            className="bg-white rounded border border-gray-400 focus:outline-none focus:border-green-600 text-base px-4 py-2 mb-4"
            placeholder="Full name"
            type="text"
          />
          <input
            className="bg-white rounded border border-gray-400 focus:outline-none focus:border-green-600 text-base px-4 py-2 mb-4"
            placeholder="Email"
            type="email"
          />
          <textarea
            className="bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-green-600 text-base px-4 py-2 mb-4 resize-none"
            placeholder="Message"
          ></textarea>
          <button className="text-white bg-green-600 border-0 py-2 px-6 focus:outline-none hover:bg-green-800 rounded text-lg">
            Submit
          </button>
          <p className="text-xs text-gray-500 mt-3">
            NTBC will reply within 24 hours
          </p>
        </div>
      </div>
    </div>
  );
};
