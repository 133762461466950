import React, { FunctionComponent } from "react";
// import saislogo from "../../assets/images/sais-logo-2.jpg";
// import idinlogo from "../../assets/images/idin-logo-1.png";
// import undplogo from "../../assets/images/undp.png";
import { useCMS, useForm, usePlugin } from "tinacms";
import { useFirestore, useFirestoreDocData } from "reactfire";

import image1 from "../../assets/news/image-1.jpeg";
import image2 from "../../assets/news/image-2.jpeg";
import image3 from "../../assets/news/image-3.jpeg";
import image4 from "../../assets/news/image-4.jpeg";

export const NewsSection: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("our-programmes");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "7.ntbc-our-programmes",
    label: "4. Our Programmes Section",
    fields: [
      {
        name: "firstLogo",
        label: "First Programme Logo",
        component: "image",
      },
      {
        name: "firstProgrammeBody",
        label: "First Programme Body",
        component: "markdown",
      },
      {
        name: "secondLogo",
        label: "Second Programme Logo",
        component: "image",
      },
      {
        name: "secondProgrammeBody",
        label: "Second Programme Body",
        component: "markdown",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="px-8 py-8 bg-gray-100">
      <div className="max-w-screen-xl mx-auto">
        <h3 className="text-3xl sm:text-5xl font-extrabold">
          News & <span className="text-green-600">Community Engagement</span>
        </h3>
        <div className="flex flex-col lg:flex-row mt-8">
          <div className="flex-1">
            <p className="text-gray-900 text-2xl font-semibold mb-2">
              Green Lundazi Village Project
            </p>
            <p className="mb-4 text-sm font-medium text-gray-500">
              Wednesday, 24th July, 2024
            </p>
            <p>
              Implementation of planned activities under Phase II of the Lundazi
              Green Village Project (LGVP II) has gained momentum. "Operations
              and Maintenance" training for four (04) identified members of
              Egichikeni Village (Senior Chief Magodi), in Chasefu District, is
              currently underway at the Kafue Gorge Regional Training Centre
              (KGRTC) in Kafue District. The training is part of the
              sustainability plan to ensure that the local community is
              capacitated to operate/manage the Solar Mini Grid.
            </p>
            <div className="grid grid-cols-3 gap-4 mt-6">
              <div>
                <img
                  src={image2}
                  alt="news"
                  className="w-full object-contain rounded-lg"
                />
              </div>
              <div>
                <img
                  src={image3}
                  alt="news"
                  className="w-full object-contain rounded-lg"
                />
              </div>
              <div>
                <img
                  src={image4}
                  alt="news"
                  className="w-full object-contain rounded-lg"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 mt-4 lg:mt-0 lg:pl-8">
            <img
              src={image1}
              alt="news"
              className="w-full object-contain rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
