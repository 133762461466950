import React, { FunctionComponent } from "react";
import Lottie from "lottie-react";
import hx from "../../assets/images/hx.svg";

import gears2 from "../../assets/lottie/19748-gear-animation.json";
import { useCMS, useForm, usePlugin } from "tinacms";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const ServicesSummary: FunctionComponent = () => {
  const cms = useCMS();
  const programmesRef = useFirestore()
    .collection("home-page")
    .doc("our-services");
  const data = useFirestoreDocData<any>(programmesRef);

  const formConfig = {
    id: "8-ntbc-services",
    label: "3. Services Section",
    fields: [
      {
        name: "servicesP1",
        label: "Services Paragraph 1",
        component: "markdown",
      },
      {
        name: "servicesP2",
        label: "Services Paragraph 2",
        component: "markdown",
      },
    ],
    initialValues: data,
    onSubmit: async (formData: any) => {
      await programmesRef.update(formData);
      cms.alerts.info("Successfully saved!");
    },
  };

  const [editableData, form] = useForm(formConfig);
  usePlugin(form);

  return (
    <div className="bg-green-600 px-8 py-8">
      <div className="max-w-screen-xl mx-auto">
        <div className="flex">
          <div className="flex-1 relative">
            <h3 className="text-3xl sm:text-5xl font-extrabold text-white">
              Our Services
            </h3>
            <p className="text-white mb-8">{editableData.servicesP1}</p>
            <p className="text-white mb-4">{editableData.servicesP2}</p>
            <p className="text-white mb-4">
              In executing our mandate, NTBC shall uphold the following values:
            </p>
            <ul className="text-white list-disc pl-4 lg:pl-16">
              <li className="py-1">Accountability</li>
              <li className="py-1">Confidentiality</li>
              <li className="py-1">Innovation</li>
              <li className="py-1">Professionalism</li>
              <li className="py-1">Transparency</li>
            </ul>
            <div className="lg:hidden block pt-4">
              <img src={hx} alt="" />
              <div className="absolute opacity-25 bottom-0 right-0">
                <Lottie animationData={gears2} />
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:flex-1 relative h-full">
            <img src={hx} alt="" className="z-20" />
            <div className="absolute top-0 opacity-25 bottom-0 right-0">
              <Lottie animationData={gears2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
